// states

export const initialStateScene = {
  setup: {
    camera: null,
    canvas: null,
    card: {
      face: {
        back: null,
        front: null
      }
    }
  }
};
